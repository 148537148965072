@font-face {
  font-family: "SUIT";
  src: url("./SUIT-Thin.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-ExtraBold.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-ExtraLight.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-Heavy.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-Light.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-Medium.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-Regular.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-SemiBold.otf") format("otf");
}
@font-face {
  font-family: "SUIT";
  src: url("./SUIT-Thin.otf") format("otf");
}
